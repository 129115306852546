<template>
  <div class="flex">
    <tojoy-card class="approve-item" :data="data" schema="people">
      <template #upcard>
        <small-user-card
          :size="46"
          :data="{
            avatar: data.avatar,
            userid: data.userid,
            username: data.username,
            position: data.position
          }"
          class="approve-item__header-right"
          @user-card="u => $emit('click-user-card', u)"
        >
        </small-user-card>
      </template>
      <template #downcard>
        <div class="approve-item__title">
          <h3>申请邀请以下员工评价</h3>
          <p :class="`approve-item__title--${data.approveState}`">{{ data.approveStateStr }}</p>
        </div>

        <approve-item-row v-for="item in data.data" :key="item.userid" :type="type" :data="item" @item-remove="handleItemRemove" @click-user-card="u => $emit('click-user-card', u)" />
        <div class="approve-item__footer">
          <p class="approve-time" v-if="data.approveTimeStr">审批时间 {{ data.approveTimeStr }}</p>
          <p class="approve-remark">
            {{ data.approveRemark }}
          </p>
        </div>
      </template>
    </tojoy-card>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui'
import TojoyCard from '@/components/business/card'
import SmallUserCard from './small-user-card.vue'
import ApproveItemRow from './approve-item-row'
import '@/components/democracy/edit-dialog.scss'

export default {
  name: 'democracy-approve-item',
  components: {
    TojoyCard,
    SmallUserCard,
    ApproveItemRow
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isApproveText: {
      // 兼容民主互评，民主互评-审核时无需输入审核内容，客户满意度-则需要输入
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      approveFormShow: false,
      approveState: 1,
      appealForm: {
        appealInput: ''
      },
      approvePeople: [],
      type: 'done'
    }
  },
  // computed: {
  //   dialogSetting() {
  //     return this.settings[this.approveState]
  //   }
  // },
  methods: {
    /**
     * 删除每一项
     */
    handleItemRemove(data) {
      this.data.data = this.data.data.filter(item => item.userid !== data.userid)
      this.$emit('item-remove', { item: data, all: this.data })
    },
    /**
     * 审核-添加 TODO: 弹框人选择列表
     */
    handleApproveAdd() {
      this.approveState = 3
      this.approveFormShow = true
    },
    /**
     * 审核-驳回
     */
    handleApproveReject() {
      if (!this.data.data.length) {
        this.$warning('请先添加员工')
        return false
      }
      if (this.isApproveText) {
        this.approveState = 2
        this.appealForm.appealInput = ''
        this.approveFormShow = true
      } else {
        MessageBox.confirm(`确定驳回申请吗？`, '提示', {
          confirmButtonText: '确定驳回',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            Message.success(`驳回成功`)
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      }
    },
    /**
     * 审核-同意
     */
    handleApprovePass() {
      if (!this.data.data.length) {
        this.$warning('请先添加员工')
        return false
      }
      if (this.isApproveText) {
        this.approveState = 1
        this.appealForm.appealInput = ''
        this.approveFormShow = true
      } else {
        MessageBox.confirm(`确定通过申请吗?`, '提示', {
          confirmButtonText: '确定同意',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            Message.success(`操作成功`)
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      }
    },
    /**
     * 添加确认 TODO
     */
    handleAdd() {
      let _arr = [...this.approvePeople]
      let _params = []
      _arr.map(item => {
        const _findIndex = this.data.data.findIndex(d => d.userid === item.userId)
        if (_findIndex === -1) {
          _params.push(item)
        } else {
          // this.$msgError(`${item.userName}已存在列表中, 请勿重复添加`)
        }
      })

      this.approveFormShow = false
      this.approvePeople = []
      this.$emit('submit-add', {
        appealInput: _params ?? [],
        curData: this.data
      })
    },
    /**
     * 审核确认
     */
    handleAudit() {
      this.approveFormShow = false
      this.$emit('submit-audit', {
        text: this.appealForm?.appealInput ?? '',
        state: this.approveState,
        curData: this.data
      })
    }
  }
}
</script>
<style lang="scss">
.approve-item {
  .tojoy-card--down {
    .approve-item-row:last-child {
      .small-user-card {
        flex: 1;
        padding: 16px 0;
        border-bottom: none;
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $lightblack;
    line-height: 16px;
    margin: 15px 5px 10px 55px;
    display: flex;
    justify-content: space-between;
    &--10 {
      color: #06ae56;
    }
    &--12 {
      color: $red;
    }
    &--13 {
      color: $red;
    }
    &--14 {
      color: $red;
    }
  }
  &__footer {
    font-size: 12px;
    float: right;
    // display: flex;
    // justify-content: flex-end;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin: 20px 24px 20px 0;
    .approve-time {
      font-size: 13px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
    .approve-remark {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__header-right {
    .small-user-card__right {
      cursor: auto;
      & > div {
        height: 100%;
      }
    }
    .el-divider {
      &--vertical {
        // margin-left: 30px;
        margin: 0 0 0 30px;
      }
    }
    .h-right {
      padding-top: 5px;
      margin-left: 30px;
      font-size: 15px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 15px;
      cursor: pointer;
      &:before {
        margin-right: 10px;
      }
      &.add {
        color: $darkgrey;
      }
      &.reject {
        color: $red;
      }
      &.pass {
        color: $green;
      }
      & > i {
        margin-left: 10px;
      }
    }
  }
}
.flex {
  flex: 1;
  display: flex;
}
</style>
