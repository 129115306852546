<template>
  <div class="approve-item-row">
    <el-popconfirm title="确定删除吗？" v-if="type == 'detele'" @confirm="$emit('item-remove', data)">
      <template #reference>
        <span class="el-icon-remove"></span>
      </template>
    </el-popconfirm>
    <small-user-card
      :size="38"
      :data="{
        userid: data.userid,
        avatar: data.avatar,
        username: data.username,
        position: data.position,
        organization: data.organization
      }"
      @user-card="u => $emit('click-user-card', u)"
    >
      <template #right><span class="el-icon-arrow-right"></span></template>
    </small-user-card>
  </div>
</template>

<script>
import SmallUserCard from './small-user-card.vue'
export default {
  name: 'democracy-approve-item-row',
  components: {
    SmallUserCard
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'detele'
    }
  }
}
</script>
<style lang="scss">
.approve-item-row {
  display: flex;
  justify-content: space-around;
  margin-left: 55px;
  .el-icon {
    &-remove {
      width: 18px;
      height: 18px;
      color: $red;
      opacity: 0.99;
      margin-top: 27px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .small-user-card {
    flex: 1;
    padding: 16px 0;
    border-bottom: 1px solid $linecolor;
    .el-icon-arrow-right {
      color: $grey550;
      opacity: 0.9;
      margin-top: 16px;
    }
  }
}
</style>
