<template>
  <div class="democracy-underlings-view">
    <tojoy-title-content title="团队互评">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <!-- {{ $store.state.democracy.teamTabs }} -->
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <!-- 我的团队 -->
    <tojoy-pagination-list v-if="tabName === 'myTeam'" :loading="loading" :data="receivedData.list" :total="receivedData.total || 0" @page-change="val => handleChangePage(val, 'myTeam')">
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.month).slice(5, 7),
            unit: String(data.month).slice(0, 4)
          }"
        >
          <template #rightcard>
            <underlings-item v-for="(item, index) in data.list" :key="index" :data="item" @click-user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })" />
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>

    <!-- 待我审批 -->
    <tojoy-pagination-list
      v-if="tabName === 'approve'"
      key="democracy-approve"
      schema="block"
      :nodata-schema="nodataSchema"
      :loading="loading"
      :total="approveData.total"
      :size="pagination.size"
      :data="approveData.list"
      @page-change="val => handleChangePage(val, 'approve')"
    >
      <template #item="{data}">
        <approve-item
          :data="data"
          :key="data.userid"
          :is-approve-text="true"
          :page-type="pageType"
          @submit-audit="handleSubmitAudit"
          @submit-add="handleSubmitAdd"
          @item-remove="handleAuditItemRemove"
          @click-user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })"
        />
      </template>
    </tojoy-pagination-list>

    <!-- 我已审批 -->
    <tojoy-pagination-list
      v-if="tabName === 'alreadyApprove'"
      key="democracy-approve"
      schema="block"
      :nodata-schema="nodataSchema"
      :loading="loading"
      :total="doneApproveData.total"
      :size="pagination.size"
      :data="doneApproveData.list"
      @page-change="val => handleChangePage(val, 'alreadyApprove')"
    >
      <template #item="{data}">
        <done-approve-item :data="data" :key="data.userid" :is-approve-text="true" @click-user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })" />
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { mapActions, mapState } from 'vuex'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyTabs from '@/components/business/tabs'
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import UnderlingsItem from '@/components/democracy/underlings-item'
import ApproveItem from '@/components/democracy/approve-item'
import DoneApproveItem from '@/components/democracy/doneApprove-item'
import { handleDataBF, BF_TYPE } from './handle_data'
import { teamEvaluateList, getWaitList, submitApprove, getFinisList } from '@/api/api_democracy'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import watchBroswerBackMixins from '@/mixins/satisfaction/watchBroswerBack'
export default {
  name: 'democracy-query',
  mixins: [tabsRouteMixins, watchBroswerBackMixins],
  components: {
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyCard,
    TojoyTabs,
    UnderlingsItem,
    ApproveItem,
    DoneApproveItem
  },
  props: {
    moduleName: {
      type: String,
      default: 'myTeam'
    }
  },
  data() {
    return {
      approveData: {}, // 待我审核数据:
      doneApproveData: {}, //我已审批数据
      receivedData: [], //我的团队数据
      tabName: this.moduleName,
      pageType: "mzhp",//客户满意度
      pagination: {
        page: 1,
        size: 10
      },
      loading: false,
      nodataSchema: 'notApprove' //无数据展示的类型
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.democracy.teamTabs
    })
  },
  created() {
    this.loading = true
    this.handleTabClick({ name: this.tabName })
    this.loadApproveRed()
  },
  methods: {
    ...mapActions('democracy', ['loadApproveRed']), //
    /**
     * 加载审核数据列表
     */
    async loadApproveData(params, options = {}) {
      this.loading = true
      const { code, data } = await getWaitList({ ...params }, options)
      if (code === '000000') {
        this.approveData = handleDataBF(data, BF_TYPE.approveList)
        this.loading = false
      }
    },
    /**
     * 提交审核表单
     */
    async handleSubmitAudit({ text, state, curData }) {
      const { code, data } = await submitApprove({
        approveRemark: text,
        approveState: state,
        approveId: curData.id,
        evaluateInviteUserId: curData.inviteUserId, //邀请发起人信息id
        invitedUserIdList: curData.data.map(u => u.userid),
        version: curData.version
      })
      if (code === '000000') {
        Message.success(`操作成功`)
        this.loadApproveData({ ...this.pagination })
        this.loadApproveRed()
        this.$parent.navApproveRed() //父组件侧边导航栏红点
      }
    },
    /**
     * 审核人员-添加
     */
    async handleSubmitAdd({ appealInput, curData }) {
      let _list = []
      for (let i = 0; i < appealInput.length; i++) {
        let aItem = appealInput[i]
        _list.push({
          invitedUserId: aItem.userId,
          invitedUserName: aItem.userName
        })
      }
      for (let d = 0; d < appealInput.length; d++) {
        let dItem = appealInput[d]
        curData.data.push({
          avatar: dItem.avatar,
          organization: dItem.mainOrgChain,
          position: dItem.mainJobName,
          userid: dItem.userId,
          username: dItem.userName
        })
        // }
      }
    },

    /**
     * 待我审核-删除某一项
     */
    async handleAuditItemRemove({ item, all }) {
      Message.success(`删除成功`)
    },
    // 我已审批列表
    async loadDoneApproveData(params, options = {}) {
      this.loading = true
      const { code, data } = await getFinisList({ ...params }, options)
      if (code === '000000') {
        this.doneApproveData = handleDataBF(data, BF_TYPE.approveList)
        this.loading = false
      }
    },

    // 我的团队数据
    async loadReceivedData(params, options = {}) {
      this.loading = true
      const { code, data } = await teamEvaluateList({ ...params }, options)
      if (code === '000000') {
        this.receivedData = handleDataBF(data, BF_TYPE.teamReceivedList)
        this.loading = false
      }
    },
    // tab切换
    handleTabClick({ name }) {
      this.currentPage = 1
      switch (name) {
        case 'approve':
          this.loadApproveData({ ...this.pagination })
          break
        case 'alreadyApprove':
          this.loadDoneApproveData({ ...this.pagination })
          break
        case 'myTeam':
          this.loadReceivedData({ ...this.pagination })
          break
      }
    },
    handleChangePage(val, type) {
      this.currentPage = val
      switch (type) {
        case 'myTeam':
          this.loadReceivedData({ page: val, size: this.pagination.size }, { loading: true })
          break
        case 'approve':
          this.loadApproveData({ page: val, size: this.pagination.size }, { loading: true })
          break
        case 'alreadyApprove':
          this.loadDoneApproveData({ page: val, size: this.pagination.size }, { loading: true })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.democracy-underlings-view {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .tojoy-pagination-list {
    flex: 1;
  }
}
</style>
