<template>
  <div class="flex">
    <tojoy-card class="approve-item" :data="data" schema="people">
      <template #upcard>
        <small-user-card
          :size="46"
          :data="{
            avatar: data.avatar,
            userid: data.userid,
            username: data.username,
            position: data.position
          }"
          class="approve-item__header-right"
          @user-card="u => $emit('click-user-card', u)"
        >
          <div slot="right" @click.stop>
            <component v-for="(item, index) in abtns" :key="index" :is="item.comp" :direction="item.direction" :class="['h-right ', item.classNames]" @click.stop="item.clickName">{{
              item.text
            }}</component>
          </div>
        </small-user-card>
      </template>
      <template #downcard>
        <h3 class="approve-item__title">申请邀请以下员工评价</h3>
        <approve-item-row v-for="item in data.data" :key="item.userid" :data="item" @item-remove="handleItemRemove" @click-user-card="u => $emit('click-user-card', u)" />
      </template>
    </tojoy-card>
    <el-dialog :destroy-on-close="true" :title="dialogSetting.title" append-to-body center :close-on-click-modal="false" :visible.sync="approveFormShow" width="560px" custom-class="appeal-dialog">
      <tojoy-select v-show="approveState === 3" v-model="approvePeople" :reserveKeyword="false" :remote="true" :disabled="false" :multiple="true" />
      <el-form ref="appealForm" v-show="[1,2, 10, 12].includes(approveState)">
        <el-form-item prop="appealInput">
          <el-input v-filteremoji ref="appealInput" type="textarea" v-model="appealForm.appealInput" :rows="6" :placeholder="dialogSetting.placeholder" maxlength="200" show-word-limit resize="none" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="approvePeople.length === 0 && approveState === 3" type="primary" @click="dialogSetting.click">{{ dialogSetting.btnText }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui'
import TojoySelect from '@/components/common/tojoy-select/person-selected'
import TojoyCard from '@/components/business/card'
import SmallUserCard from './small-user-card.vue'
import ApproveItemRow from './approve-item-row'
import '@/components/democracy/edit-dialog.scss'

export default {
  name: 'democracy-approve-item',
  components: {
    TojoySelect,
    TojoyCard,
    SmallUserCard,
    ApproveItemRow
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isApproveText: {
      // 兼容民主互评，民主互评-审核时无需输入审核内容，客户满意度-则需要输入
      type: Boolean,
      default: false
    },
    // khmyd==客户满意度  mzhp==民主互评
    pageType: {
      type: String,
      default: 'khmyd'
    }
  },
  data() {
    return {
      approveFormShow: false,
      approveState: 10,
      appealForm: {
        appealInput: ''
      },
      approvePeople: [],
      abtns: [
        {
          comp: 'span',
          classNames: 'add el-icon-circle-plus-outline',
          clickName: this.handleApproveAdd,
          text: '添加'
        },
        {
          comp: 'el-divider',
          direction: 'vertical',
          classNames: 'h-right',
          clickName: ''
        },
        {
          comp: 'span',
          classNames: 'reject icon iconfont iconreject-o',
          clickName: this.handleApproveReject,
          text: '驳回'
        },
        {
          comp: 'el-divider',
          direction: 'vertical',
          classNames: 'h-right',
          clickName: ''
        },
        {
          comp: 'span',
          classNames: 'pass el-icon-circle-check',
          clickName: this.handleApprovePass,
          text: '同意'
        }
      ],
      settings: {
        3: {
          title: '添加',
          btnText: '确 认',
          click: this.handleAdd
        },
        // 民主互评 审批状态 10 通过 12 驳回
        12: {
          title: '驳回',
          placeholder: '驳回原因(选填)',
          btnText: '提 交',
          click: this.handleAudit
        },
        10: {
          title: '同意',
          placeholder: '审核意见(选填)',
          btnText: '提 交',
          click: this.handleAudit
        },
        // 客户满意度 1:审核通过;2:审核拒绝
        2: {
          title: '驳回',
          placeholder: '驳回原因(选填)',
          btnText: '提 交',
          click: this.handleAudit
        },
        1: {
          title: '同意',
          placeholder: '审核意见(选填)',
          btnText: '提 交',
          click: this.handleAudit
        }
      }
    }
  },
  computed: {
    dialogSetting() {
      return this.settings[this.approveState]
    }
  },
  methods: {
    /**
     * 删除每一项
     */
    handleItemRemove(data) {
      this.data.data = this.data.data.filter(item => item.userid !== data.userid)
      this.$emit('item-remove', { item: data, all: this.data })
    },
    /**
     * 审核-添加 TODO: 弹框人选择列表
     */
    handleApproveAdd() {
      this.approveState = 3
      this.approveFormShow = true
    },
    /**
     * 审核-驳回
     */
    handleApproveReject() {
      if (!this.data.data.length) {
        this.$warning('请先添加员工')
        return false
      }
      if (this.isApproveText) {
        console.log('this.isApproveText===',this.isApproveText)
        if(this.pageType==='khmyd'){
          this.approveState = 2
        }
        if(this.pageType==='mzhp'){
          this.approveState = 12
        }
        console.log('this.approveState===',this.approveState)
        // this.approveState = 12
        this.appealForm.appealInput = ''
        this.approveFormShow = true
      } else {
        MessageBox.confirm(`确定驳回申请吗？`, '提示', {
          confirmButtonText: '确定驳回',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            Message.success(`驳回成功`)
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      }
    },
    /**
     * 审核-同意
     */
    handleApprovePass() {
      if (!this.data.data.length) {
        this.$warning('请先添加员工')
        return false
      }
      if (this.isApproveText) {
        console.log('this.isApproveText===',this.isApproveText,this.pageType)
         
        if(this.pageType === "khmyd"){
          this.approveState = 1
        }
        if(this.pageType === "mzhp"){
          this.approveState = 10
        }
        console.log('this.approveState===',this.approveState)
        // this.approveState = 10
        this.appealForm.appealInput = ''
        this.approveFormShow = true
      } else {
        MessageBox.confirm(`确定通过申请吗?`, '提示', {
          confirmButtonText: '确定同意',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            Message.success(`操作成功`)
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      }
    },
    /**
     * 添加确认 TODO
     */
    handleAdd() {
      let _arr = [...this.approvePeople]
      let _params = []
      _arr.map(item => {
        const _findIndex = this.data.data.findIndex(d => d.userid === item.userId)
        if (_findIndex === -1) {
          _params.push(item)
        } else {
          // this.$msgError(`${item.userName}已存在列表中, 请勿重复添加`)
        }
      })

      this.approveFormShow = false
      this.approvePeople = []
      this.$emit('submit-add', {
        appealInput: _params ?? [],
        curData: this.data
      })
    },
    /**
     * 审核确认
     */
    handleAudit() {
      // console.log(this.data, ')))', this.approveState, '状态')
      this.approveFormShow = false
      this.$emit('submit-audit', {
        text: this.appealForm?.appealInput ?? '',
        state: this.approveState,
        curData: this.data
      })
    }
  }
}
</script>
<style lang="scss">
.approve-item {
  .tojoy-card--down {
    .approve-item-row:last-child {
      .small-user-card {
        flex: 1;
        padding: 16px 0;
        border-bottom: none;
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $lightblack;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 55px;
  }
  &__header-right {
    .small-user-card__right {
      cursor: auto;
      & > div {
        height: 100%;
      }
    }
    .el-divider {
      &--vertical {
        // margin-left: 30px;
        margin: 0 0 0 30px;
      }
    }
    .h-right {
      padding-top: 5px;
      margin-left: 30px;
      font-size: 15px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 15px;
      cursor: pointer;
      &:before {
        margin-right: 10px;
      }
      &.add {
        color: $darkgrey;
      }
      &.reject {
        color: $red;
      }
      &.pass {
        color: $green;
      }
      & > i {
        margin-left: 10px;
      }
    }
  }
}
.flex {
  flex: 1;
  display: flex;
}
</style>
