<script lang="jsx">
import TojoyRate from '@/components/common/rate'
import SmallUserCard from './small-user-card.vue'
import OrganizationTip from './organization-tip'
import { DEMOCRACY_RATE_DATA } from './state.js'

export default {
  name: 'democracy-underlings-item',
  components: {
    TojoyRate,
    SmallUserCard,
    OrganizationTip
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      isShow: false,
    }
  },
  // computed: {
  //   rateList() {
  //     let _rate = [...DEMOCRACY_RATE_DATA]
  //     return _rate.map((item, index) => {
  //       item.rate = this.data[item.id] ?? 0
  //       return item
  //     })
  //   }
  // },
  methods: {
    jumpTo(userdata) {
      // TODO:跳转到该用户个人主页
    }
  },
  render(h) {
    const { data, jumpTo } = this
    return (
      <div class="democracy-underlings-item">
        <small-user-card
          data={{
            avatar: data.avatar,
            userid: data.userid,
            username: data.username,
            position: data.position,
            organization: '' //data.organization
          }}
          on-user-card={u => this.$emit('click-user-card', u)}
        >
          <i slot='right' class="score">{data.score ?? 0}分</i>
        </small-user-card>
        <organization-tip type={data.type} name={data.organization} createDate={data.createDate}  reportType={data.reportType} />
        <div class="democracy-underlings-item__wrap">
          {
            data.visitNum ? (
              <h3 class="evaluate-nums">{data.visitNum}人参评：</h3>
            ) : ''
          }
          {
            data.rateList.map((r, index) => {
              return (
                <tojoy-rate v-model={r.rate} disabled={true} title={r.title} />
              )
            })
          }
          {
            parseInt(data.suggestNum) ? (
            <div>
              <h4 class="advise-label">{data.suggestNum ?? 0}条建议</h4>
              <ol class="ol__desc-list">
              {
                data?.suggestList?.map((item, index) => {
                  return (
                    <li key={index}>
                      <span class="info-desc__dist"></span>
                      <p class="info-desc__text">
                        {item}
                      </p>
                    </li>
                  )
                })
              }
              </ol>
            </div>
            ) : ''
          }
        </div>
      </div>
    )
  }
}
</script>

<style lang="scss">
.democracy-underlings-item {
  margin-left: 20px;
  padding-top: 16px;
  .small-user-card {
    margin-bottom: 15px;
    .score {
      font-size: 16px;
      font-weight: bold;
      color: $blue;
      line-height: 16px;
    }
  }
  &__wrap {
    padding-bottom: 20px;
    border-bottom: 1px solid $linecolor;
    margin-left: 0; // 48px;
    overflow: hidden;
  }
  .evaluate-nums {
    font-size: 16px;
    font-weight: bold;
    color: $lightblack;
    line-height: 16px;
  }
  .tojoy-rate {
    margin-bottom: 11px;
    &:first-of-type {
      margin-top: 15px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .advise {
    &-label {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      color: $lightblack;
      line-height: 14px;
    }
  }
  &:first-of-type {
    padding-top: 5px;
  }
  &:last-of-type {
    .democracy-underlings-item__wrap {
      border-bottom: none;
    }
  }
}
</style>
